import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import config from "./config";
import {
  PublicClientApplication,
  EventType,
  LogLevel,
  Configuration,
  AccountInfo,
  AuthenticationResult
} from "@azure/msal-browser";

import response from "./authConfig";

response.then((res) => {
  const msalConfig: Configuration = {
    auth: {
      clientId: res.data.getClientVariables.clientId,
      authority: `https://login.microsoftonline.com/${res.data.getClientVariables.tenantId}`,
      redirectUri: "/",
      postLogoutRedirectUri: "/",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPII) => {
          console.log(message);
        },
        logLevel: LogLevel.Info,
      },
    },
  };

  const pca = new PublicClientApplication(msalConfig);

  // Function to acquire token silently
  const acquireToken = async (account: AccountInfo | null) => {
    if (!account) return;

    try {
      const tokenResponse = await pca.acquireTokenSilent({
        scopes: ["User.Read"], // Ensure this matches the API scopes you need
        account,
        forceRefresh: true, //Force a new token instead of returning the cached one
      });

      console.log("New Token Acquired:", tokenResponse.accessToken);

      // Store updated tokens in localStorage
      localStorage.setItem(config.tokenKey, tokenResponse.accessToken);
      localStorage.setItem(config.tokenClaimsKey, JSON.stringify(tokenResponse.idTokenClaims));

    } catch (error) {
      console.error("Silent token acquisition failed", error);
    }
  };

  // Get existing account after page reload
  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    const activeAccount = accounts[0];
    pca.setActiveAccount(activeAccount);

    // Immediately refresh the token on reload
    acquireToken(activeAccount);

    // Start the silent token refresh every 50 minutes (outside event callback)
    setInterval(() => acquireToken(activeAccount), 10 * 60 * 1000); // Refresh every 50 minutes
  }

  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const authResult = event.payload as AuthenticationResult;

      if (authResult.account) {
        pca.setActiveAccount(authResult.account);

        const idTokenClaims = authResult.account.idTokenClaims;
        if (idTokenClaims) {
          localStorage.setItem(config.tokenClaimsKey, JSON.stringify(idTokenClaims));
          if (idTokenClaims.roles) {
            localStorage.setItem(config.rolesKey, JSON.stringify(idTokenClaims.roles));
          }
        }

        localStorage.setItem(config.tokenKey, authResult.accessToken || "");

        // Start the silent token refresh every 50 minutes after login
        setInterval(() => acquireToken(authResult.account), 10 * 60 * 1000); // Refresh every 50 minutes
      }
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App
          msalInstance={pca}
          graphQLEndpoint={res.data.getClientVariables.graphQLEndpoint}
        />
      </BrowserRouter>
    </React.StrictMode>
  );
});

reportWebVitals();
