import { setContext } from "@apollo/client/link/context";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

import config from "./config";

export default function createApolloClient(
  endpoint: string,
  accessToken: string
) {
  const getAuthHeader = () => {
    //Get accessToken dynamically or from localstorage
    //@ts-ignore
    //const token = accessToken? accessToken : JSON.parse(localStorage.getItem(config.tokenKey));
    const token = localStorage.getItem(config.tokenKey);
    //@ts-ignore
    const rolesKey = JSON.parse(localStorage.getItem(config.rolesKey));
    

    if (!token) return {};

    return {
      "x-authorization": token,
      "x-allowedroles": rolesKey,
    };
  };

  const httpLink = createHttpLink({
    uri: endpoint,
    fetch,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...getAuthHeader(),
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });

  return client;
}
